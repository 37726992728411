import React from 'react';
const axios = require('axios');

class ContactForm extends React.Component{
    constructor(){
        super()
        this.state = {
          name: '',
          phone: '',
          email: '',
          messege: '',
          contact_sent: false,
          name_input_check: "w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-red-200 focus:ring-2  text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out",
          phone_input_check: "w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-red-200 focus:ring-2  text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out",
          email_input_check: "w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-red-200 focus:ring-2  text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out",
          messege_input_check: "w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-red-200 focus:ring-2  text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out",
        }

        this.name_change = this.name_change.bind(this);
        this.phone_change = this.phone_change.bind(this);
        this.email_change = this.email_change.bind(this);
        this.messege_change = this.messege_change.bind(this);

        this.handle_submit = this.handle_submit.bind(this);
        
    }

    name_change(e) {
        
        this.setState({name: e.target.value});
        if(this.state.name.length < 2){
            this.setState({name_input_check: "w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-red-200 focus:ring-2  text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"});
        }else if(this.state.name.length >= 2){
            this.setState({name_input_check: "w-full bg-white rounded border border-gray-300 focus:border-green-500 focus:ring-green-200 focus:ring-2  text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"});
        }
    }

    phone_change(e) {
        this.setState({phone: e.target.value});
        if(this.state.phone.length < 8){
            this.setState({phone_input_check: "w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-red-200 focus:ring-2  text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"});
        }else if(this.state.phone.length >= 8){
            this.setState({phone_input_check: "w-full bg-white rounded border border-gray-300 focus:border-green-500 focus:ring-green-200 focus:ring-2  text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"});
        }
    }

    email_change(e) {
        this.setState({email: e.target.value});
        if(this.state.email.length < 5){
            this.setState({email_input_check: "w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-red-200 focus:ring-2  text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"});
        }else if(this.state.email.length >= 5){
            this.setState({email_input_check: "w-full bg-white rounded border border-gray-300 focus:border-green-500 focus:ring-green-200 focus:ring-2  text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"});
        }
    }

    messege_change(e) {
        this.setState({messege: e.target.value});
        if(this.state.messege.length < 10){
            this.setState({messege_input_check: "w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-red-200 focus:ring-2  text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"});
        }else if(this.state.messege.length >= 10){
            this.setState({messege_input_check: "w-full bg-white rounded border border-gray-300 focus:border-green-500 focus:ring-green-200 focus:ring-2  text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"});
        }
    }

    handle_submit(event){
        event.preventDefault();
        if(this.state.name.length > 1 && this.state.email.length > 1 && this.state.messege.length > 1 ){        
            axios({
                method: 'post',
                url: '/api/sendmail/',
                data: {
                    name: this.state.name,
                    phone: this.state.phone,
                    email: this.state.email,
                    messege: this.state.messege,
                }
            })

            this.setState({
                contact_sent: true,
            });
        }else {
            this.setState({
                contact_sent: false,
            });
        }
    }

    render(){
        return(
            <>
                {this.state.contact_sent ? 
                    <div className="w-full py-20 text-center">
                        <h3 className="text-2xl font-bold">Vi återkommer inom kort.</h3>
                    </div>
                :
                <div className="bg-transparent flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">     
                    <form className="relative mb-4">
                        <label htmlFor="name" className="leading-7 text-sm text-gray-600">Namn</label>
                        <input  
                            placeholder="Jane Doe"
                            value={this.state.name}
                            onChange={this.name_change}
                            type="text" 
                            id="name" 
                            name="name" 
                            className={this.state.name_input_check}/>
                    </form>

                    <div className="relative mb-4">
                        <label htmlFor="phone" className="leading-7 text-sm text-gray-600">Telefonnummer</label>
                        <input 
                            placeholder="070-000 00 00"
                            value={this.state.phone}
                            onChange={this.phone_change}
                            type="text" 
                            id="phone" 
                            name="phone" 
                            className={this.state.phone_input_check}/>
                    </div>

                    <div className="relative mb-4">
                        <label htmlFor="email" for="email" className="leading-7 text-sm text-gray-600">E-postadress</label>
                        <input 
                            placeholder="namn@exempel.se" 
                            value={this.state.email}
                            onChange={this.email_change}
                            type="email" 
                            id="email" 
                            name="email" 
                            className={this.state.email_input_check}/>
                    </div>

                    <div className="relative mb-4">
                        <label htmlFor="message" className="leading-7 text-sm text-gray-600">Meddelande</label>
                        <textarea 
                            placeholder="Hur kan vi hjälpa dig?" 
                            value={this.state.messege}
                            onChange={this.messege_change}
                            id="message" 
                            name="message" 
                            className={this.state.messege_input_check}/>
                    </div>

                    <button type="submit" className="text-white bg-green-500 border-0 py-2 px-6 focus:outline-none hover:bg-green-600 rounded text-lg" onClick={this.handle_submit}>Skicka</button>            
                </div>
                } 
            </>
        );
    }
}
export default ContactForm;