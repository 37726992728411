import React from 'react';
import {Link} from 'gatsby';
import Chatbot from './chatbot/chatbot';

import { ImLocation2, ImPhone, ImMail3 } from 'react-icons/im';
import { FaFacebookSquare, FaGoogle } from 'react-icons/fa';

import logo from '../images/danas-plat-och-smide.png';
import mvr_logo from "../images/mvr.png"

function Footer(){
    return(
        <>
        <footer className="text-gray-800 bg-gray-200 body-font">
           
                <div className="w-full h-px bg-gradient-to-r from-gray-100 via-gray-200 to-green-400"></div>
          
            <div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
                <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left md:mt-0 mt-10">
                    <div className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
                        <img src={logo} alt="Danas plåt och smide" className="w-full" />
                    </div>
                    <p className="mt-4 text-sm text-gray-600">Vi är medlem i MVR (Mekaniska Verkstädernas Riksförbund) vilket betyder att vi har tillgång till ett stort nätverk av erfarna samarbetspartners där vi skapar värde tillsammans.</p>
                    <img className="mx-auto lg:mx-0 mt-4 h-12" src={mvr_logo} alt="" />
                    <span className="inline-flex sm:ml-auto mt-4 justify-center sm:justify-start">
                        <a target="_blank" rel="noreferrer" href="https://www.facebook.com/Danas-Pl%C3%A5t-och-Smide-AB-522625454447240" >
                            <FaFacebookSquare className="text-5xl text-blue-500 hover:text-blue-600" />
                        </a>
                        <a target="_blank" rel="noreferrer" href="https://www.google.com/search?q=danas+plåt+och+smide+ab">
                            <FaGoogle className="text-5xl text-yellow-400 hover:text-yellow-500" />
                        </a>
                        
                    </span>
                </div>
                <div className="flex-grow flex flex-wrap md:pr-20 -mb-10 md:text-left text-center order-first">
                    <div className="xl:w-1/4 md:w-1/2 w-full px-4 ">
                        <h2 className="title-font font-bold text-green-800 tracking-widest text-sm mb-3">Hitta oss</h2>
                        <nav className="list-none mb-10">
                            <li>
                                <span className="flex justify-center md:justify-start text-gray-600 hover:text-gray-800"><ImLocation2 className="mr-1 mt-1"/>Vasa Gård, 151 52 Södertälje</span>
                            </li>
                            <li>
                                <span className="flex justify-center md:justify-start  text-gray-600 hover:text-gray-800"><ImPhone className="mr-1 mt-1"/>08-550 175 11</span>
                            </li>
                            <li>
                                <span className="flex justify-center md:justify-start text-gray-600 hover:text-gray-800 "><ImMail3 className="mr-1 mt-1"/>info@danasplat.se</span>
                            </li>
                        </nav>
                    </div>
                    <div className="xl:w-1/4 md:w-1/2 w-full px-4">
                        <h2 className="title-font font-bold text-green-800 tracking-widest text-sm mb-3">Länkar</h2>
                        <nav className="list-none mb-10">
                            <li>
                                <Link to="/" className="text-gray-600 hover:text-green-800">Hem</Link>
                            </li>
                            <li>
                                <Link to="/tjanster" className="text-gray-600 hover:text-green-800">Tjänster</Link>
                            </li>
                            <li>
                                <Link to="/om-oss" className="text-gray-600 hover:text-green-800">Om oss</Link>
                            </li>
                            <li>
                                <Link to="/kontakt" className="text-gray-600 hover:text-green-800">Kontakt</Link>
                            </li>
                            <li>
                                <a href="/policy" target="_blank" rel="noreferrer" className="text-gray-600 hover:text-green-800">Verksamhetspolicy</a>
                            </li>
                        </nav>
                    </div>
                    <div className="xl:w-1/4 md:w-1/2 w-full px-4">
                        <h2 className="title-font font-bold text-green-800 tracking-widest text-sm mb-3">Våra Tjänster</h2>
                        <nav className="list-none mb-10">
                            <li>
                                <Link to="/byggnadssmide" className="text-gray-600 hover:text-gray-800">Byggnadssmide</Link>
                            </li>
                            <li>
                                <Link to="/svetsarbete" className="text-gray-600 hover:text-gray-800">Svetsarbete</Link>
                            </li>
                            <li>
                                <Link to="/plasma" className="text-gray-600 hover:text-gray-800">Plasma bearbetning</Link>
                            </li>
                            <li>
                                <Link to="/pallservice" className="text-gray-600 hover:text-gray-800">Pallservice</Link>
                            </li>
                            <li>
                                <Link to="/montering" className="text-gray-600 hover:text-gray-800">Montering och rivning</Link>
                            </li>
                            <li>
                                <Link to="/logistik" className="text-gray-600 hover:text-gray-800">Transport och logistik</Link>
                            </li>
                            <li>
                                <Link to="/tillverkning" className="text-gray-600 hover:text-gray-800"> Tillverkning</Link>
                            </li>
                            <li>
                                <Link to="/blastring" className="text-gray-600 hover:text-gray-800">Isblästring / kolsyreblästring</Link>
                            </li>
                            <li>
                                <Link to="/malning" className="text-gray-600 hover:text-gray-800">Industrimålning och rostskyddsmålning </Link>
                            </li>
                            <li>
                                <Link to="/lager" className="text-gray-600 hover:text-gray-800">Lagerförvaring</Link>
                            </li>

                            <li>
                                <Link to="/uthyrning" className="text-gray-600 hover:text-gray-800">Bemanning och uthyrning </Link>
                            </li>
                        </nav>
                    </div>
                    <div className="xl:w-1/4 md:w-1/2 w-full px-4">
                        <h2 className="title-font font-bold text-green-800 tracking-widest text-sm mb-3">Öppettider</h2>
                        <nav className="list-none mb-10">
                            <li className="flex flex-row items-center justify-center md:justify-start">
                                <span className="w-20">måndag</span>
                                <span className="text-gray-600 hover:text-gray-800">06.50 - 16</span>
                            </li>
                            <li className="flex flex-row items-center justify-center md:justify-start">
                                <span className="w-20">tisdag</span>
                                <span className="text-gray-600 hover:text-gray-800">06.50 - 16</span>
                            </li>
                            <li className="flex flex-row items-center justify-center md:justify-start">
                                <span className="w-20">onsdag</span>
                                <span className="text-gray-600 hover:text-gray-800">06.50 - 16</span>
                            </li>
                            <li className="flex flex-row items-center justify-center md:justify-start">
                                <span className="w-20">torsdag</span>
                                <span className="text-gray-600 hover:text-gray-800">06.50 - 16</span>
                            </li>
                            <li className="flex flex-row items-center justify-center md:justify-start">
                                <span className="w-20">fredag</span>
                                <span className="text-gray-600 hover:text-gray-800">06.50 - 13</span>
                            </li>
                            <li className="flex flex-row items-center justify-center md:justify-start">
                                <span className="w-20">lördag</span>
                                <span className="text-gray-600 hover:text-gray-800">STÄNGT</span>
                            </li>
                            <li className="flex flex-row items-center justify-center md:justify-start">
                                <span className="w-20">söndag</span>
                                <span className="text-gray-600 hover:text-gray-800">STÄNGT</span>
                            </li>
                        </nav>
                    </div>                
                </div>
            </div>

            <div className="bg-gray-200">
                <div className="container mx-auto py-4 px-5">
                    <p className="text-gray-500 text-sm text-center sm:text-center">©Danas Plåt och Smide AB -
                        <a href="https://hamraz.se/" rel="external" className="text-gray-600 ml-1">@Hamraz Webbdesign</a>
                    </p>
                </div>
            </div>
        </footer>
        <Chatbot />
    </>
    );
}
export default Footer;