import './chatbot.css';
import React from 'react';
import ContactForm from '../form';

import { BsFillChatDotsFill } from 'react-icons/bs';

class Chatbot extends React.Component{
    constructor(){
        super()
        this.state = {
          chatbot_open: false  
        }

        this.handle_chat_click = this.handle_chat_click.bind(this);
        
    }

    handle_chat_click(event){
        event.preventDefault();
        if(this.state.chatbot_open === true){
            this.setState({chatbot_open: false});
        }

        if(this.state.chatbot_open === false){
            this.setState({chatbot_open: true});
        }
    }

    render(){
        return(
            <div className="chatbot">
                { this.state.chatbot_open ? 
                    <div className="form_box">
                        <ContactForm />
                    </div>
                    : ""  
                }
                
                <div className="chatt_wrapper">
                    <button onClick={this.handle_chat_click} className="catt_box">
                        {this.state.chatbot_open ? <h2 style={{fontWeight: '500'}}>X</h2> : <BsFillChatDotsFill/>  }
                    </button>
                </div>
            </div>
        );
    }
}
export default Chatbot;